import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Workorder from './Workorder';
import Users from './Users';
import UserRoles from './UserRoles';
import WorkorderOperation from './WorkorderOperation';
import WorkorderManagemnt from './WorkOrderManagement';
import Common from './Common';
import Customer from './Customer';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  workorder: Workorder,
  workorderOperation: WorkorderOperation,
  customers: Customer,
  workorderManagemnt: WorkorderManagemnt,
  userRoles: UserRoles,
  users: Users
});

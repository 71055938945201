import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};


export const userSignUp = ({name, email, username, password}) => async dispatch => {
    try {
      dispatch({type: FETCH_START});
      const res = await axios.post('api/users', {
          email: email,
          username: username,
          password: password,
          name: name
        }
      );
      let data = res.data
      console.log("data:", data);
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
      axios.defaults.headers.common['x-auth-token'] = data.token;
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: USER_TOKEN_SET, payload: data.token});
      dispatch({type: USER_DATA, payload: data.user});
    } catch(err) {
      const errors = err.response.data.errors;
      if (errors && errors.length) {
        dispatch({type: FETCH_ERROR, payload: errors[0].msg});
        dispatch({type: HIDE_MESSAGE});
      } else {
        console.log("Error****:", err);
      }
    };
};

// export const userSignUpDelete = ({name, email, password}) => {
//   console.log(name, email, password);
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     axios.post('api/users', {
//         email: email,
//         password: password,
//         name: name
//       }
//     ).then(({data}) => {
//       console.log("data:", data);
//       if (data.result) {
//         localStorage.setItem("token", JSON.stringify(data.token.access_token));
//         axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
//         dispatch({type: FETCH_SUCCESS});
//         dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
//         dispatch({type: USER_DATA, payload: data.user});
//       } else {
//         console.log("payload: data.error", data.error);
//         dispatch({type: FETCH_ERROR, payload: "Network Error"});
//       }
//     }).catch(function (error) {
//       dispatch({type: FETCH_ERROR, payload: error.message});
//       console.log("Error****:", error.message);
//     });
//   }
// };

export const userSignIn = ({username, password}) => async dispatch => {
    try {
      dispatch({type: FETCH_START});
      const res = await axios.post('api/auth', {
          username: username,
          password: password,
        }
      );
      let data = res.data
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
      axios.defaults.headers.common['x-auth-token'] = data.token;
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: USER_TOKEN_SET, payload: data.token});
      dispatch({type: USER_DATA, payload: data.user});
    } catch(err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
        dispatch({type: HIDE_MESSAGE});
        // errors.forEach(error => dispatch({type: SHOW_MESSAGE, payload: error.msg}));
        // errors.forEach(error => dispatch({type: HIDE_MESSAGE, payload: error.msg}));
      } else {
        console.log("Error****:", err);
      }
    };
};



// export const userSignIn = ({email, password}) => {
//   return (dispatch) => {
//     console.log("userSignInEnter: ", email);
//     console.log("userSignInEnter2: ", password);
//     dispatch({type: FETCH_START});
//     axios.post('auth/login', {
//         email: email,
//         password: password,
//       }
//     ).then(({data}) => {
//       console.log("userSignIn: ", data);
//       if (data.result) {
//         localStorage.setItem("token", JSON.stringify(data.token.access_token));
//         axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
//         dispatch({type: FETCH_SUCCESS});
//         dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
//       } else {
//         dispatch({type: FETCH_ERROR, payload: data.error});
//       }
//     }).catch(function (error) {
//       dispatch({type: FETCH_ERROR, payload: error.message});
//       console.log("Error****:", error.message);
//     });
//   }
// };

// Load User
export const getUser = () => async dispatch => {
  try {
    const res = await axios.get('/api/auth');
    dispatch({
      type: USER_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SIGNOUT_USER_SUCCESS
    });
  }
};

// export const getUser = () => {
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     axios.post('auth/me',
//     ).then(({data}) => {
//       console.log("userSignIn: ", data);
//       if (data.result) {
//         dispatch({type: FETCH_SUCCESS});
//         dispatch({type: USER_DATA, payload: data.user});
//       } else {
//         dispatch({type: FETCH_ERROR, payload: data.error});
//       }
//     }).catch(function (error) {
//       dispatch({type: FETCH_ERROR, payload: error.message});
//       console.log("Error****:", error.message);
//     });
//   }
// };

export const userSignOut = () => dispatch => {
  // Logout / Clear Profile
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch({ type: SIGNOUT_USER_SUCCESS });
};

// export const userSignOut = () => {
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     axios.post('auth/logout',
//     ).then(({data}) => {
//       if (data.result) {
//         dispatch({type: FETCH_SUCCESS});
//         localStorage.removeItem("token");
//         dispatch({type: FETCH_SUCCESS});
//         dispatch({type: SIGNOUT_USER_SUCCESS});
//       } else {
//         dispatch({type: FETCH_ERROR, payload: data.error});
//       }
//     }).catch(function (error) {
//       dispatch({type: FETCH_ERROR, payload: error.message});
//       console.log("Error****:", error.message);
//     });
//   }
// };

import { SET_USERS, SET_USER, CHANGE_USERS_TAB, NEW_USER } from "../../constants/ActionTypes";

const INIT_STATE = {
  newUser: false,
  usersList: [],
  userData: {
    name: "",
    access: {
      dashboard: false,
      workOrder: false,
      customerMaster: false,
      companySetup: false,
      users: false
    }
  },
  tabValue: { value: 0 }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case NEW_USER: {
      return { ...state, newUser: action.payload };
    }

    case SET_USERS: {
      return {
        ...state,
        usersList: action.payload
      }
    }

    case SET_USER: {
      return {
        ...state,
        userData: action.payload,
      };
    }

    case CHANGE_USERS_TAB: {
      return {
        ...state,
        tabValue: action.payload,
      };
    }

    default:
      return state;
  }
}

import {SET_WORKORDERS, FETCH_START, FETCH_SUCCESS, FETCH_ERROR, SET_WORKORDER, CHANGE_WORKORDER_TAB, SHOW_MESSAGE, HIDE_MESSAGE, UPDATE_WORKORDER_QUERY_CONFIG} from "constants/ActionTypes";
import axios from 'util/Api'

export const getWorkorderList = (showCompleted, dueInTenDays, showOnlyExpedite, showOnlyHotProject) => async dispatch => {
    try {
      dispatch({type: FETCH_START});
      const res = await axios.post('api/workorder/list',{'showCompleted' : showCompleted, 'dueInTenDays': dueInTenDays, 'showOnlyExpedite': showOnlyExpedite, 'showOnlyHotProject': showOnlyHotProject});
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SET_WORKORDERS, payload: res.data});
    } catch(err) {
      const errors = err.response?.data.errors;
      if (errors) {
        errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
        dispatch({type: HIDE_MESSAGE});
      } else {
        console.log("Error****:", err);
      }
    };
};

export const createOrUpdateWorkorder = (workorderData) => async dispatch => {
  console.log('workorderData: Dispatch', workorderData);
  try {
    dispatch({type: FETCH_START});
    const res = await axios.post('api/workorder/createOrUpdate', workorderData);
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SHOW_MESSAGE, payload: 'Work order saved'});
    dispatch({type: HIDE_MESSAGE});
    dispatch({type: SET_WORKORDER, payload: res.data});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

export const deleteWorkorder = (workorder) => async dispatch => {
  try {
    dispatch({type: FETCH_START});
    const res = await axios.post('api/workorder/delete', { 'workOrder' : workorder });
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SHOW_MESSAGE, payload: 'Work order deleted!'});
    dispatch({type: HIDE_MESSAGE});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

export const cloneWorkOrder = (woCloneGridData, workOrderToClone) => async dispatch => {
  try {
    dispatch({type: FETCH_START});
    const res = await axios.post('api/workorder/clone', { 'woCloneGridData' : woCloneGridData, 'workOrderToClone' : workOrderToClone });
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SHOW_MESSAGE, payload: 'Work order(s) saved!'});
    dispatch({type: HIDE_MESSAGE});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

export const getWorkorder = (workorder) => async dispatch => {
  try {
    dispatch({type: FETCH_START});
    const res = await axios.get(`api/workorder/${workorder}`);
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SET_WORKORDER, payload: res.data});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

export const setWorkorderList = (workorderList) => {
  return {
    type: SET_WORKORDERS,
    payload: workorderList
  }
};

export const showWorkorder = (workorder) => {
  return {
    type: SET_WORKORDER,
    payload: workorder
  }
};

export const changeWoTab = (tabValue) => {
  return {
    type: CHANGE_WORKORDER_TAB,
    payload: tabValue
  }
};

export const initiateNewWorkorder = () => {
  const workOrder = {
    isCreating : true
  }
  return {
    type: SET_WORKORDER,
    payload: workOrder
  }
};

export const updateWoQueryConfig = (showCompleted, dueInTenDays, showOnlyExpedite, showOnlyHotProject) => {
  return {
    type: UPDATE_WORKORDER_QUERY_CONFIG,
    payload: {
      'showCompleted' : showCompleted, 'dueInTenDays': dueInTenDays, 'showOnlyExpedite': showOnlyExpedite, 'showOnlyHotProject': showOnlyHotProject
    }
  }
};

import { SET_CUSTOMERS, SET_CUSTOMER, CHANGE_CUSTOMERS_TAB, NEW_CUSTOMER } from "../../constants/ActionTypes";

const INIT_STATE = {
  newCustomer: false,
  customersList: [],
  customerData: {
    customerId: "",
    phoneNumber: "",
    customerName: "",
    adress1: "",
    adress2: "",
    city: "",
    state: "",
    zipCode: "",
    contactPersonName: "",
    email: "",
    salesRep: "",
    shippingMethod: "",
    shippingAccount: ""
  },
  tabValue: { value: 0 }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case NEW_CUSTOMER: {
      return { ...state, newCustomer: action.payload };
    }

    case SET_CUSTOMERS: {
      return {
        ...state,
        customersList: action.payload
      }
    }

    case SET_CUSTOMER: {
      return {
        ...state,
        customerData: action.payload,
      };
    }

    case CHANGE_CUSTOMERS_TAB: {
      return {
        ...state,
        tabValue: action.payload,
      };
    }

    default:
      return state;
  }
}
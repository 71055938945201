import {SET_WORKORDERS, SET_WORKORDER, CHANGE_WORKORDER_TAB, UPDATE_WORKORDER_QUERY_CONFIG} from "../../constants/ActionTypes";

const INIT_STATE = {
  workorderList: [],
  workorderData: {},
  tabValue: {value: 0},
  workorderQueryConfig: {
    'showCompleted' : false, 'dueInTenDays': false
  },
  dashboardColumns: [
    { title: "Work Order", field: "workOrder" },
    {
      title: "Status",
      field: "status",
      lookup: { W: "Waiting", S: "Started", C: "Completed", H: "On Hold" },
      cellStyle: (value) => ({
        backgroundColor:
          value != "Completed"
            ? value == "Waiting"
              ? "#4de2f9"
              : value == "On Hold"
              ? "#FF264D"
              : "#f4f742"
            : "#2aed82",
      }),
    },
    { title: "Last Operation", field: "lastOperation" },
    { title: "Due Date", field: "dueDate", type: "date" },
    { title: "Commit Date", field: "commitDate", type: "date" },
    { title: "Customer", field: "customer" },
    { title: "Project", field: "project" },
    { title: "PO Number", field: "poNumber" },
    { title: "Release Number", field: "releaseNumber", type: "numeric" },
    { title: "Order Quantity", field: "orderQuantity", type: "numeric" },
    { title: "Modified By", field: "whoModified" },
    { title: "Date Completed", field: "completedDate", type: "date" },
    { title: "Completed By", field: "completedBy" },
    { title: "Date Shipped", field: "shippedDate", type: "date" },
    { title: "Hot Project", field: "hotProject"},
  ]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_WORKORDERS: {
      if (action.payload && action.payload.length) {
        for (let i = 0; i < state.dashboardColumns.length; i++) {
          if (state.dashboardColumns[i].field == 'lastOperation') {
            let operationMap = {};
            for (let i = 0; i < action.payload.length; i++) {
              if (action.payload[i].lastOperation) {
                operationMap[action.payload[i].lastOperation] = action.payload[i].lastOperation;
              }
            }
            state.dashboardColumns[i].lookup = operationMap;
          }
        }
      }
      return {
        ...state,
        workorderList: action.payload
      }
    }

    case SET_WORKORDER: {
      return {
        ...state,
        workorderData: action.payload,
      };
    }

    case CHANGE_WORKORDER_TAB: {
      return {
        ...state,
        tabValue: action.payload,
      };
    }

    case UPDATE_WORKORDER_QUERY_CONFIG: {
      return {
        ...state,
        workorderQueryConfig: action.payload,
      };
    }

    default:
      return state;
  }
}

import axios from './Api'

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = JSON.parse(token);
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
};

export default setAuthToken;
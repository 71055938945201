import { SET_USER_ROLES, SET_USER_ROLE, CHANGE_USER_ROLES_TAB, NEW_USER_ROLE } from "../../constants/ActionTypes";

const INIT_STATE = {
  newUserRole: false,
  userRolesList: [],
  userRoleData: {
    roleName: "",
    access: {
      dashboard: false,
      workOrder: false,
      customerMaster: false,
      companySetup: false,
      userRoles: false,
      users: false
    }
  },
  tabValue: { value: 0 }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case NEW_USER_ROLE: {
      return { ...state, newUserRole: action.payload };
    }

    case SET_USER_ROLES: {
      return {
        ...state,
        userRolesList: action.payload
      }
    }

    case SET_USER_ROLE: {
      return {
        ...state,
        userRoleData: action.payload,
      };
    }

    case CHANGE_USER_ROLES_TAB: {
      return {
        ...state,
        tabValue: action.payload,
      };
    }

    default:
      return state;
  }
}

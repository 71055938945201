import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {userSignIn} from 'store/actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";

const SignIn = (props) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const {loading, message, error} = useSelector(({commonData}) => commonData);

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token]);

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img src={require("assets/images/logo.png")} alt="jambo" title="jambo"/>
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1><IntlMessages id="appModule.welcome"/></h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.username"/>}
                  fullWidth
                  onChange={(event) => setUsername(event.target.value)}
                  defaultValue={username}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password"/>}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button onClick={() => {
                    dispatch(userSignIn({username, password}));
                  }} variant="contained" color="primary">
                    <IntlMessages id="appModule.signIn"/>
                  </Button>

                  <Link to="/signup">
                    <IntlMessages id="signIn.signUp"/>
                  </Link>
                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {
        loading &&
        <div className="loader-view">
          <CircularProgress/>
        </div>
      }
      {message && NotificationManager.info(message)}
      {error && NotificationManager.error(error)}
      <NotificationContainer/>

    </div>
  );
};


export default SignIn;

import {SELECTED_WORK_ORDER_MANAGEMENT_ROW} from "../../constants/ActionTypes";

const INIT_STATE = {
  selectedWorkOrderManagement: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECTED_WORK_ORDER_MANAGEMENT_ROW: {
      return {
        ...state,
        selectedWorkOrderManagement: action.payload
      }
    }
    default:
      return state;
  }
}

import {GET_WORKORDER_OPERATIONS, ADD_WORKORDER_OPERATION, UPDATE_WORKORDER_OPERATIONS} from "../../constants/ActionTypes";

const INIT_STATE = {
  workorderOperationList: [],
  workorder: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case UPDATE_WORKORDER_OPERATIONS:
    case GET_WORKORDER_OPERATIONS: {
      return {
        ...state,
        workorderOperationList: action.payload
      }
    }

    case ADD_WORKORDER_OPERATION: {
      return {
        ...state,
        workorderOperationList: [...state.workorderOperationList, action.payload]
      }
    }

    default:
      return state;
  }
}

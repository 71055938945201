import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR, SET_USERS, SET_USER, CHANGE_USERS_TAB, SHOW_MESSAGE, HIDE_MESSAGE, NEW_USER } from "constants/ActionTypes";
import axios from 'util/Api'

export const getUsersList = () => async dispatch => {
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.get('api/users/list');
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SET_USERS, payload: res.data });
  } catch (err) {
    const errors = err.response?.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};

export const createUser = (userData) => async dispatch => {
  console.log('userData: Dispatch', userData);
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.put('api/users/add', userData);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SHOW_MESSAGE, payload: 'User saved' });
    dispatch({ type: HIDE_MESSAGE });
    dispatch({ type: CHANGE_USERS_TAB, payload: { value: 0 } });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(err.response)
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};


export const updateUser = (userData) => async dispatch => {
  console.log('userData: Dispatch', userData);
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.put('api/users/edit', userData);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SHOW_MESSAGE, payload: 'User saved' });
    dispatch({ type: HIDE_MESSAGE });
    dispatch({ type: CHANGE_USERS_TAB, payload: { value: 0 } });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};

export const deleteUser = (user) => async dispatch => {
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.delete(`api/users/remove/${user._id}`);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SHOW_MESSAGE, payload: 'User deleted!' });
    dispatch({ type: HIDE_MESSAGE });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};

export const setNewUserStatus = (status) => {
  return {
    type: NEW_USER,
    payload: status
  }
};

export const setUsersList = (usersList) => {
  return {
    type: SET_USERS,
    payload: usersList
  }
};

export const setUser = (user) => {
  console.log(user)
  return {
    type: SET_USER,
    payload: user
  }
};

export const changeUsersTab = (tabValue) => {
  return {
    type: CHANGE_USERS_TAB,
    payload: tabValue
  }
};
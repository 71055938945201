import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { COMPANY_SETUP, CUSTOMER_MASTER, USER_ROLES, WORKORDER, WORKORDER_MANAGEMENT } from '../../constants/Pages'
import asyncComponent from '../../util/asyncComponent';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const ROUTES = [
  {
    page: WORKORDER_MANAGEMENT,
    url: '/workorder-management',
    Component: asyncComponent(() => import('./WorkorderManagement'))
  },
  {
    page: WORKORDER,
    url: '/workorder',
    Component: asyncComponent(() => import('./Workorder'))
  },
  {
    page: CUSTOMER_MASTER,
    url: '/customerMaster',
    Component: asyncComponent(() => import('./CustomerMaster'))
  },
  {
    page: COMPANY_SETUP,
    url: '/smtp-setup',
    Component: asyncComponent(() => import('./SmtpSetup'))
  },
  {
    page: COMPANY_SETUP,
    url: '/users',
    Component: asyncComponent(() => import('./Users'))
  },
  {
    page: COMPANY_SETUP,
    url: '/list-of-template',
    Component: asyncComponent(() => import('./listOfTemplate'))
  },
  {
    page: USER_ROLES,
    url: '/user-roles',
    Component: asyncComponent(() => import('./UserRoles'))
  }
]

const Routes = ({ match }) => {
  const { access } = useSelector(state => state.auth.authUser);
  return <Switch>
    {
      ROUTES.reduce((filtered, { page, url, Component }) => {
        if (page === WORKORDER_MANAGEMENT || access[page]) {
          filtered.push(<Route path={`${match.url}${url}`} component={Component} />)
        }
        return filtered
      }, [])
    }
  </Switch>;
}


export default withRouter(Routes);


import {GET_WORKORDER_OPERATIONS, UPDATE_WORKORDER_OPERATIONS, FETCH_START, FETCH_SUCCESS, FETCH_ERROR, SHOW_MESSAGE, HIDE_MESSAGE} from "constants/ActionTypes";
import axios from 'util/Api'

export const getWorkorderOperationList = (workorder) => async dispatch => {
    try {
      dispatch({type: FETCH_START});
      const res = await axios.get(`api/woOperation/list/${workorder}`);
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: GET_WORKORDER_OPERATIONS, payload: res.data});
    } catch(err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
        dispatch({type: HIDE_MESSAGE});
      } else {
        console.log("Error****:", err);
      }
    };
};

export const createOrUpdateWorkorderOperation = (operationData) => async dispatch => {
  try {
    dispatch({type: FETCH_START});
    const res = await axios.post('api/woOperation/createOrUpdate', operationData);
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: UPDATE_WORKORDER_OPERATIONS, payload: res.data.operationList});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

export const deleteWorkorderOperation = (workorder, operationCode) => async dispatch => {
  try {
    dispatch({type: FETCH_START});
    const res = await axios.delete(`api/woOperation/delete/${workorder}/${operationCode}`);
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: UPDATE_WORKORDER_OPERATIONS, payload: res.data});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

export const completeWorkorderOperation = (workorder, operationCode, completeAll, quantityToComplete) => async dispatch => {
  try {
    dispatch({type: FETCH_START});
    const res = await axios.post('api/woOperation/complete', { workOrder: workorder, operationCode: operationCode, completeAll: completeAll, quantityToComplete: quantityToComplete});
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: UPDATE_WORKORDER_OPERATIONS, payload: res.data.operationList});
    dispatch({type: SHOW_MESSAGE, payload: 'Workder operation updated!'});
    dispatch({type: HIDE_MESSAGE});
  } catch(err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({type: FETCH_ERROR, payload: error.msg}));
      dispatch({type: HIDE_MESSAGE});
    } else {
      console.log("Error****:", err);
    }
  };
};

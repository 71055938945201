import React from 'react';
import { useSelector } from 'react-redux';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import { COMPANY_SETUP, CUSTOMER_MASTER, USER_ROLES, WORKORDER, WORKORDER_MANAGEMENT } from '../../constants/Pages'

const NAVIGATION_CHILDRENS = [
  {
    page: WORKORDER_MANAGEMENT,
    name: 'sidebar.dashboard',
    type: 'item',
    link: '/app/workorder-management',
    icon: 'view-dashboard'
  },
  {
    page: WORKORDER,
    name: 'pages.workorder',
    type: 'item',
    link: '/app/workorder',
    icon: 'collection-bookmark'
  },
  {
    page: CUSTOMER_MASTER,
    name: 'sidebar.customerMaster',
    type: 'item',
    link: '/app/customerMaster',
    icon: 'accounts-list'
  },
  {
    page: COMPANY_SETUP,
    name: 'sidebar.companySetup',
    type: 'collapse',
    icon: 'balance',
    children: [
      {
        name: 'sidebar.smtpSetup',
        type: 'item',
        link: '/app/smtp-setup',
        icon: 'email',
      },
      {
        name: 'sidebar.listOfTemplate',
        type: 'item',
        link: '/app/list-of-template',
        icon: 'file-text',
      },
      {
        name: 'sidebar.users',
        type: 'item',
        link: '/app/users',
        icon: 'accounts-outline',
      },
      {
        name: 'sidebar.userRoles',
        type: 'item',
        link: '/app/user-roles',
        icon: 'accounts-list'
      }
    ]
  }
]

const SideBarContent = () => {
  const { access } = useSelector(state => state.auth.authUser),
    navigationMenus = [
      {
        name: 'sidebar.main',
        type: 'section',
        children: [...NAVIGATION_CHILDRENS.reduce((filtered, child) => {
          if (access[child.page]) {
            filtered.push({ ...child, page: undefined })
          }
          return filtered
        }, [])]
      },
    ];

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;

import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR, SET_CUSTOMERS, SET_CUSTOMER, CHANGE_CUSTOMERS_TAB, SHOW_MESSAGE, HIDE_MESSAGE, NEW_CUSTOMER } from "constants/ActionTypes";
import axios from 'util/Api'

export const getCustomersList = () => async dispatch => {
  try {
    console.log("res")
    dispatch({ type: FETCH_START });
    const res = await axios.get('api/customers/list');
    console.log(res)
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SET_CUSTOMERS, payload: res.data });
  } catch (err) {
    const errors = err.response?.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};

export const createCustomer = (customerData) => async dispatch => {
  console.log('customerData: Dispatch', customerData);
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.put('api/customers/add', customerData);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SHOW_MESSAGE, payload: 'Customer saved' });
    dispatch({ type: HIDE_MESSAGE });
    dispatch({ type: CHANGE_CUSTOMERS_TAB, payload: { value: 0 } });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(err.response)
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};


export const updateCustomer = (customerData) => async dispatch => {
  console.log('customerData: Dispatch', customerData);
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.put('api/customers/edit', customerData);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SHOW_MESSAGE, payload: 'Customer saved' });
    dispatch({ type: HIDE_MESSAGE });
    dispatch({ type: CHANGE_CUSTOMERS_TAB, payload: { value: 0 } });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};

export const deleteCustomer = (customer) => async dispatch => {
  try {
    dispatch({ type: FETCH_START });
    const res = await axios.delete(`api/customers/remove/${customer._id}`);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SHOW_MESSAGE, payload: 'Customer deleted!' });
    dispatch({ type: HIDE_MESSAGE });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch({ type: FETCH_ERROR, payload: error.msg }));
      dispatch({ type: HIDE_MESSAGE });
    } else {
      console.log("Error****:", err);
    }
  };
};

export const setNewCustomerStatus = (status) => {
  return {
    type: NEW_CUSTOMER,
    payload: status
  }
};

export const setCustomersList = (customersList) => {
  return {
    type: SET_CUSTOMERS,
    payload: customersList
  }
};

export const setCustomer = (customer) => {
  console.log(customer)
  return {
    type: SET_CUSTOMER,
    payload: customer
  }
};

export const changeCustomersTab = (tabValue) => {
  return {
    type: CHANGE_CUSTOMERS_TAB,
    payload: tabValue
  }
};